import './application'
import '~/vendor/themes/soft-ui/javascript/soft-ui-dashboard'
import '~/vendor/themes/soft-ui/javascript/plugins/sweetalert.min'
import Choices from 'choices.js'

// Datatables

import DataTable from 'datatables.net-dt';
import 'datatables.net-responsive';
import 'datatables.net-bs5'
import 'datatables.net-buttons'
import 'datatables.net-buttons-bs5'

$('.datatable').livequery(function() {
  let datatable = new DataTable($(this)[0], {
    serverSide: true,
    ordering: false,
    autoWidth: false,
    ajax: $(this).data('datatable-url'),
    oLanguage: {
      sSearch: 'Search',
    },
    language: {
      lengthMenu: 'Entries per page _MENU_',
      info: 'Showing <var start>_START_</var> to <var end>_END_</var> of <var total>_TOTAL_</var> _ENTRIES-TOTAL_',
      infoFiltered: '(filtered from <var max>_MAX_</var> total entries)'
    }
  });

  // Attach the draw event handler
  datatable.on('draw', function (e, settings, json, xhr) {
    // Create an array containing all table headers
    var table_headers = [];
    $(this).find('thead th').each(function() {
      table_headers.push($(this).text());
    });

    // Add a data-label attribute to each cell with the value of the corresponding column header
    $(this).find('tbody tr').each(function() {
      $(this).find('td').each(function(column) {
        $(this).attr('data-label', table_headers[column]);
      });
    });
  });
});


$(document).ready(function() {
  DataTable.ext.sErrMode = 'ignore'; // do not raise datatables errors as alerts
});

$('.table:not(.datatable)').livequery(function() {
  // Create an array containing all table headers
  var table_headers = [];
  $(this).find('thead th').each(function() {
    table_headers.push($(this).text());
  });

  // Add a data-label attribute to each cell with the value of the corresponding column header
  $(this).find('tbody tr').each(function() {
    $(this).find('td').each(function(column) {
      $(this).attr('data-label', table_headers[column]);
    });
  });
});

// Update export links when datatables are searched
document.body.addEventListener('change', (e) => {
  if (e.target.matches('.dt-search input[type=search]')) {
    const baseHref = new URL(document.querySelector('.export-link').getAttribute('href'), window.location);
    baseHref.searchParams.set('search[value]', e.target.value);
    document.querySelector('.export-link').setAttribute('href', baseHref.toString());
  }
});

$('body.client-website select:visible:not(.react-select)').livequery(function() {
  // If the placeholder has no text specified in BackOffice - passed to here as an empty string, then insert generic placeholder
  // Reason: the empty string renders poorly
  let placeholder = $("option[value='']", this)
  if (placeholder.length > 0 && placeholder.text().trim() === '') {
    placeholder.text('Select an option...');
  }

  const choiceSelect = new Choices(this, {
    searchPlaceholderValue: 'Type here to search...',
    itemSelectText: false,
    allowHTML: true,
    callbackOnCreateTemplates: (strToEl, escapeForTemplate, getClassNames) => ({
      choice: (...args) => {
        const customContent = args[1].element.dataset?.content;

        if (customContent) {
          args[1].label = customContent;
        }

        return Object.assign(Choices.defaults.templates.choice.call(this, ...args));
      }

    }),
  });

  // Needs to be here as choices messes with the dom
  $(this).on('chosen:updated', function() {
    const chosenValue = this.value;

    choiceSelect.refresh();
    choiceSelect.setChoiceByValue(chosenValue);
  });

  $(this).on('chosen:hide', function() {
    $(this).parents('.choices').hide()
  });

  $(this).on('chosen:show', function() {
    $(this).parents('.choices').show()
  });
});
